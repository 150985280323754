import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  closeCircleOutline,
  closeOutline,
  createOutline,
  documentTextOutline,
  ellipsisVertical,
  linkOutline,
  newspaperOutline,
  pricetagOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tShipment, tShipments } from "../../store/types/shipping.types";

import useAlert from "../../hooks/useAlert/useAlert";

import { TRACKING_BASE_URL } from "../../data";

// optimized solution for time between setting route and opening select rider modal
const ShipmentActions = ({
  shipment,
  setShipments,
  reloadShipments,
}: {
  shipment: tShipment;
  setShipments: React.Dispatch<React.SetStateAction<tShipments>>;
  reloadShipments?: () => void;
}) => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [error, setError] = useState("");
  const [success, setSuccess] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const [cancelShipment, setCancelShipment] = useState("");
  const [confirmCancelShipment, setConfirmCancelShipment] = useState("");

  const [reason, setReason] = useState("");

  const updateStatusBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const [message, setMessage, clearMessage] = useAlert();

  const updateShipmentStatus = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!reason) return setMessage("warning", "Fill in all fields");

    const btnHTML = updateStatusBtnRef.current.innerHTML;

    updateStatusBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    updateStatusBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: `/shipments/${cancelShipment}`,
      method: "PATCH",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        Status: "CANCELED",
        Reason: reason,
      },
    })
      .then((res) => {
        setShipments((shipmts) =>
          shipmts.map((shipment) =>
            shipment._id === res.data.data._id ? res.data.data : shipment
          )
        );

        setCancelShipment("");

        setSuccess({
          title: "Status canceled!",
          message: "Shipment canceled successfully",
        });

        if (reloadShipments) reloadShipments();
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage(
            "error",
            "An error occured while trying to update status. Try again"
          );
        }
      })
      .finally(() => {
        updateStatusBtnRef.current.removeAttribute("disabled");
        updateStatusBtnRef.current.innerHTML = btnHTML;
      });
  };

  useEffect(() => {
    clearMessage();
  }, [reason, clearMessage]);

  useEffect(() => {
    if (cancelShipment) return;

    setReason("");
  }, [cancelShipment]);

  return (
    <>
      {confirmCancelShipment ? (
        <div>
          <div className="success-modal">
            <h3>Confirmation</h3>
            <p className="text-center">
              Are you sure you want to cance this shipment
            </p>
            <div className="success-modal__btns">
              <button
                className="btn btn--info"
                onClick={() => {
                  setConfirmCancelShipment("");
                }}
              >
                Close
              </button>
              <button
                className="btn btn--primary"
                onClick={() => {
                  setConfirmCancelShipment("");
                  setCancelShipment(confirmCancelShipment);
                }}
              >
                Continue
              </button>
            </div>
          </div>
          <div
            className="overlay"
            onClick={() => setConfirmCancelShipment("")}
          ></div>
        </div>
      ) : null}
      {error ? (
        <div>
          <div className="success-modal">
            <div className="icon warning">
              <span className="body"></span>
              <span className="dot"></span>
            </div>
            <p className="text-center">{error}</p>
          </div>
          <div className="overlay" onClick={() => setError("")}></div>
        </div>
      ) : null}
      {success ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>{success.title}</h3>
            <p className="text-center">{success.message}</p>
            <div className="success-modal__btns">
              <button className="btn" onClick={() => setSuccess(null)}>
                Close
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setSuccess(null)}></div>
        </div>
      ) : null}
      <div>
        <form
          className={cls("modal modal--sm", cancelShipment && "modal--open")}
          onSubmit={updateShipmentStatus}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Cancel Shipment</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => setCancelShipment("")}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              <div className="form-group">
                <label>
                  Reason <span>*</span>
                </label>
                <textarea
                  rows={5}
                  className="form-input"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Enter reason for canceling"
                ></textarea>
              </div>
              {message}
            </div>
          </div>
          <div className="modal__footer">
            <div></div>
            <div>
              <button
                className="btn btn--primary"
                type="submit"
                ref={updateStatusBtnRef}
              >
                Cancel Shipment
              </button>
            </div>
          </div>
        </form>
        {cancelShipment ? (
          <div className="overlay" onClick={() => setCancelShipment("")}></div>
        ) : null}
      </div>
      <div className="flex-center">
        <div className="shipments-menu">
          <div className="shipments-menu__main">
            <IonIcon icon={ellipsisVertical} />
          </div>
          <div className="shipments-menu__block">
            <div className="shipments-menu__block-main">
              {shipment.Status === "CREATED" ? (
                <>
                  <div
                    onClick={() => navigate(`/edit-shipment/${shipment._id}`)}
                  >
                    <IonIcon icon={createOutline} /> Edit Shipment
                  </div>
                </>
              ) : null}
              {shipment.Booked ? (
                <a
                  href={`${TRACKING_BASE_URL}/${shipment.TrackingNumber}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IonIcon icon={linkOutline} /> Track on DMX
                </a>
              ) : null}
              {shipment.Label ? (
                <a href={shipment.LabelPath} target="_blank" rel="noreferrer">
                  <IonIcon icon={pricetagOutline} /> View / Print Label
                </a>
              ) : null}
              <a
                href={`${process.env.PUBLIC_URL}/waybill/${shipment._id}`}
                target="_blank"
                rel="noreferrer"
              >
                <IonIcon icon={documentTextOutline} /> View / Print Waybill
              </a>
              <a
                href={`${process.env.PUBLIC_URL}/invoice/${shipment.Invoice}`}
                target="_blank"
                rel="noreferrer"
              >
                <IonIcon icon={newspaperOutline} /> View Invoice
              </a>
              {shipment.Status === "PRE-TRANSIT" &&
              !(shipment.Carrier.Type === "External" && shipment.Booked) ? (
                <div onClick={() => setConfirmCancelShipment(shipment._id)}>
                  <IonIcon icon={closeCircleOutline} /> Cancel Shipment
                </div>
              ) : null}
            </div>
            <div className="shipments-menu__block-top"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipmentActions;
