import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";

import Dashboard from "./pages/Dashboard/Dashboard";
import Shipments from "./pages/Shipments/Shipments";
import Invoice from "./pages/Invoice/Invoice";
import Waybill from "./pages/Waybill/Waybill";
import NewShipment from "./pages/NewShipment/NewShipment";
import ShipmentsImport from "./pages/ShipmentsImport/ShipmentsImport";
import SingleShipmentsImport from "./pages/SingleShipmentsImport/SingleShipmentsImport";

import Login from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";

import LoginDetails from "./pages/Register/LoginDetails/LoginDetails";
import VerifyEmail from "./pages/Register/VerifyEmail/VerifyEmail";
// import VerifyTelephone from "./pages/Register/VerifyTelephone/VerifyTelephone";
import AddProfilePicture from "./pages/Register/AddProfilePicture/AddProfilePicture";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/shipments" element={<Shipments />} />
        <Route path="/invoice/:id" element={<Invoice />} />
        <Route path="/waybill/:id" element={<Waybill />} />
        <Route path="/new-shipment" element={<NewShipment />} />
        <Route path="/edit-shipment/:id" element={<NewShipment />} />
        <Route path="/import" element={<ShipmentsImport />} />
        <Route path="/import/:id" element={<SingleShipmentsImport />} />

        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/register" element={<LoginDetails />} />
        <Route path="/register/verify-email" element={<VerifyEmail />} />
        {/* <Route
          path="/register/verify-telephone"
          element={<VerifyTelephone />}
        /> */}
        <Route path="/register/add-dp" element={<AddProfilePicture />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
