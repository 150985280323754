import { FormEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { IonIcon } from "@ionic/react";
import { chevronDownOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";

import api_client from "../../../api/client";

import { tRootState } from "../../../store";
import { tCountry } from "../../../store/types/app.types";
import { login } from "../../../store/userReducer";

import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";

import useSelectBox from "../../../hooks/useSelectBox/useSelectBox";
import useAlert from "../../../hooks/useAlert/useAlert";
import useData from "../../../hooks/useData/useData";

const LoginDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { countries, countriesSelectData } = useSelector(
    (state: tRootState) => state.cache
  );

  const { fetchCountries } = useData();

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");

  const [countrySelectBox, selectedCountryId, openSelectBox] = useSelectBox(
    "Select Country",
    countriesSelectData
  );

  const [telephoneCountry, setTelephoneCountry] = useState<tCountry | null>(
    null
  );
  const [telephone, setTelephone] = useState("");

  const [emailAddress, setEmailAddress] = useState("");

  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, set_message, clear_message] = useAlert();

  const handleSignup = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!telephoneCountry || !telephone || !emailAddress || !password)
      return set_message("warning", "Fill in all fields");

    submitBtnRef.current.setAttribute("disabled", "disabled");
    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;

    api_client({
      url: "/users/signup",
      method: "POST",
      data: {
        Name: name,
        Company: company,
        EmailAddress: emailAddress,
        TelephoneCountry: telephoneCountry._id,
        Telephone: telephone,
        Password: password,
      },
    })
      .then((res) => {
        dispatch(login(res.data.data));
        navigate("/register/verify-email");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          set_message("warning", err.response.data.message);
        } else {
          set_message("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Create Account";
        }
      });
  };

  useEffect(() => {
    if (!selectedCountryId) return;

    setTelephoneCountry(countries.find((ct) => ct._id === selectedCountryId)!);
  }, [selectedCountryId, countries]);

  useEffect(() => {
    clear_message();
  }, [telephoneCountry, telephone, emailAddress, password, clear_message]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  return (
    <AuthLayout>
      {countrySelectBox}
      <main className="auth__main">
        <div className="auth__logo-box">
          <h3 className="auth__brand">DMX Logistics</h3>
        </div>
        <div className="auth__btns">
          <Link to="/login" className="auth__btn">
            Login
          </Link>
          <Link to="#" className="auth__btn auth__btn--active">
            Create account
          </Link>
        </div>
        <div className="auth__header2">
          <div className="auth__header2-main">
            <h3 className="auth__heading">Register</h3>
            <p className="auth__sub-heading">
              Kindly fill in the details below to create account
            </p>
          </div>
        </div>
        <form className="auth__form" onSubmit={handleSignup}>
          <div className="form-grid">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter last name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Company name</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter company name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label"> Phone Number </label>
            <div className="form-input-group form-input-group--1">
              <button onClick={openSelectBox} type="button">
                {telephoneCountry ? (
                  <p>{telephoneCountry.TelephoneCode}</p>
                ) : (
                  "Select country"
                )}
                <IonIcon icon={chevronDownOutline} />
              </button>
              <input
                type="tel"
                placeholder="Your phone number"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              className="form-input"
              placeholder="Enter email address"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <div className="form-input-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword((sp) => !sp)}
              >
                <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
              </button>
            </div>
          </div>
          <button
            className="button mt-small"
            ref={submitBtnRef}
            disabled={
              !name ||
              !company ||
              !telephoneCountry ||
              !emailAddress ||
              !password
            }
          >
            Create Account
          </button>
          {message}
        </form>
      </main>
    </AuthLayout>
  );
};

export default LoginDetails;
