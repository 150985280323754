import { Link, useNavigate } from "react-router-dom";

import withAuth from "../../../hoc/withAuth/withAuth";

import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";

const AddProfilePicture = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <main className="auth__main">
        <div className="auth__logo-box">
          <h3 className="auth__brand">DMX Logistics</h3>
        </div>
        <div className="auth__btns">
          <Link to="/login" className="auth__btn">
            Login
          </Link>
          <Link to="#" className="auth__btn auth__btn--active">
            Create account
          </Link>
        </div>
        <div className="auth__header2">
          <div className="auth__header2-main">
            <h3 className="auth__heading">Profile Picture</h3>
            <p className="auth__sub-heading">
              This will represent your profile photo. This is not a mandatory
              step, you can skip this step
            </p>
          </div>
          <div className="auth__header2-right">
            <p className="auth__header2-step">Step 3/3</p>
          </div>
        </div>
        <div className="align-center mb-medium">
          <img
            src="https://alley-whatsapp-clone.netlify.app/assets/img/user-avatar.svg"
            alt=""
            className="auth__img"
          />
        </div>
        <p className="auth__sub-heading align-center mb-medium">
          <strong>Note:</strong> Uploaded image should not be larger than 2MB is
          size and only the following file formats are accepted: png, jpg, jpeg
        </p>
        <div className="button-group-2">
          <button className="button1">Change Image</button>
          <button className="button" onClick={() => navigate("/")}>
            Finish
          </button>
        </div>
      </main>
    </AuthLayout>
  );
};

export default withAuth(AddProfilePicture);
