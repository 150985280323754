import { tUser } from "../store/types/app.types";

type StepType = "verify-email" | "verify-telephone";

export const getRegistrationStep = (
  userDetails: tUser | null
): StepType | null => {
  if (!userDetails) return null;
  if (userDetails.AccountCreationStatus === 2) return null;

  if (!userDetails.EmailAddReference?.Verified) {
    return "verify-email";
  }
  //   else if (!userDetails.TelephoneAddReference?.Verified) {
  //     return "verify-telephone";
  //   }

  return null;
};

export const getStepPath = (step: StepType): string => {
  if (step === "verify-email") return "/register/verify-email";
  if (step === "verify-telephone") return "/register/verify-telephone";

  return "/";
};
